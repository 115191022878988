import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user.js";
import ui from "./modules/ui.js";
import ais from "./modules/ais.js";
import upload_data from "./modules/upload_data.js";
import inr from "./modules/inr.js";
import map from "./modules/map.js";
import download from "./modules/download.js";
import alerts from "./modules/alerts.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    ui,
    upload_data,
    inr,
    map,
    download,
    ais,
    alerts,
  },
});
