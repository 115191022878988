import { upload } from "../../services/api";

const state = () => ({
  statusUploadFile: "default", // default, success, error
  statusUploadNoData: "default", // default, success, error
  fileMsg: "File Info",
  noDataMsg: "No Data Info",
  status: "ok",
});

const actions = {
  submitData(context, { mutationType, url, data, file, fileZip }) {
    // context.commit("setStatusUi", "isFetching", { root: true });
    context.commit('status_request')
    if (mutationType == "setStatusUploadNoData") {
      data.airgunNoise == false &&
      data.explosionNoise == false &&
      data.genericExplicitlyNoise == false &&
      data.impactPileNoise == false &&
      data.sonarNoise == false
        ? (data = "error")
        : (data = data);
    }
    upload(url, data, file, fileZip)
      .then((res) => {
        if (res.status >= 500) {
          // Error 500
          context.commit(mutationType, "error");
          context.commit(
            "setStatusFileMsg",
            "Error - The server is not working properly. Please, contact the administrator of QUIETSEAS"
          );
        } else if (res.status >= 400) {
          // Error 400
          context.commit(mutationType, "error");
          context.commit(
            "setStatusFileMsg",
            "Error - Error uploading the data."
          );
        } else if (res.status >= 200) {
          context.commit(mutationType, "success", "Success");

          if (mutationType == "setStatusUploadNoData") {
            context.commit("setStatusNoDataMsg", "Data received successfully");
          }
          if (mutationType == "setStatusUploadFile") {
            context.commit("setStatusFileMsg", "Data uploaded successfully. The changes will be visible on the map in a few minutes.");
          }
        }
        context.commit('status_ok')
      })
      .catch((error) => {
        context.commit(
          mutationType,
          "error",
          "Error en la función <Submit Data>"
        );
      });
  },
  resetMessages(context) {
    context.commit("setStatusFileMsg", "");
    context.commit("setStatusNoDataMsg", "");
  },
};

const mutations = {
  setStatusUploadFile(state, statusUploadFile) {
    state.statusUploadFile = statusUploadFile;
  },
  setStatusFileMsg(state, fileMsg) {
    state.fileMsg = fileMsg;
  },
  setStatusUploadNoData(state, statusUploadNoData) {
    state.statusUploadNoData = statusUploadNoData;
  },
  setStatusNoDataMsg(state, NoDataMsg) {
    state.noDataMsg = NoDataMsg;
  },
  status_request(state) {
    state.status = "loading";
  },
  status_ok(state) {
    state.status = "ok";
  },
};

const getters = {
  getStatusUploadFile: (state) => state.statusUploadFile,
  getStatusUploadNoData: (state) => state.statusUploadNoData,
  getStatusfileMsg: (state) => state.fileMsg,
  getStatusNoDataMsg: (state) => state.noDataMsg,
  status: (state) => state.status,

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
