import axios from "axios";
import * as localData from "@/services/localData.js";
import Vue from "vue";
import { postData,getDataAIS,getGeoserverLayer,getData } from "@/services/api.js";
import {generarColorDesdeString,getUniqueShipTypes,getUniqueSightingsTypes } from "@/services/helper.js";
import router from '@/router';

const state = () => ({
  typeOfShip: [],
  typeOfsighting: [],
  shipsData: [], // Aquí se almacenan los datos de las embarcaciones
  sightingsData: [], 
  selectedShipTypes: [],
  selectedSightingTypes: [],
  showLayers: {
    ships: true,
    routes: false,
    habitat: false,
    sightings: false,
    riskOfImpact: false,
    soundPressure: false,
    controlZone: false,
    impactZone: false,
  }
});

const mutations = {
  setShowLayers(state,data) {
    state.showLayers[data[0]] = data[1];
  },
  setTypesOfShip(state, data) {
    state.typeOfShip = data;
  },
  setTypeOfsighting(state, data) {
    state.typeOfsighting = data;
  },
  setShipsData(state, data) {
    state.shipsData = data;
  },
  setSightingsData(state, data) {
    state.sightingsData = data;
  },
  setSelectedShipTypes(state, data) {
    state.selectedShipTypes = data;
  },
  setSelectedSightingTypes(state, data) {
    state.selectedSightingTypes = data;
  },
};

const actions = {
  changeShowLayers({ commit,getters }, data) {  
    if(!getters.showLayers.ships && !getters.showLayers.routes)
      commit("setSelectedShipTypes", []);

    if(getters.showLayers.sightings)
      commit("setSelectedSightingTypes", []);

    commit("setShowLayers", data);
  },
  async changeTypesOfData({ commit }) {
      var res = await getDataAIS('processingAISData/ship-3D-ids/')
      var res_ships = await getGeoserverLayer(process.env.VUE_APP_GEOSERVER_AIS_LAYER)
      res_ships = res_ships.data.features
      var uniqueShipTypes = getUniqueShipTypes(res_ships)
      commit("setTypesOfShip",uniqueShipTypes);

      var res_sightings = await getGeoserverLayer(process.env.VUE_APP_GEOSERVER_SIGHTINGS_LAYER)
      res_sightings = res_sightings.data.features
      var uniqueSightingsTypes = getUniqueSightingsTypes(res_sightings)
      commit("setTypeOfsighting", uniqueSightingsTypes)
  },
  changeShipsData({ commit }, data) {

    var uniqueShipTypes = getUniqueShipTypes(data)
    commit("setTypesOfShip",uniqueShipTypes);

    commit("setShipsData", data);
  },
  changeSelectedShipTypes({ commit }, data) {
    commit("setSelectedShipTypes", data);
  },
  changeSelectedSightingTypes({ commit }, data) {
    commit("setSelectedSightingTypes", data);
  },
  changeSightingsData({ commit }, data) {

    var uniqueSightingsTypes = getUniqueSightingsTypes(data)
    commit("setTypeOfsighting", uniqueSightingsTypes)

    commit("setSightingsData", data);
  },
  resetConfig({ commit }) {

    commit("setShowLayers", ['ships',true]);
    commit("setShowLayers", ['sightings',false]);
    commit("setShowLayers", ['controlZone',false]);
    commit("setShowLayers", ['impactZone',false]);
    commit("setSelectedShipTypes", []);
    commit("setSelectedSightingTypes", []);
  },
  async getShips({ dispatch }, url) {
    if(router.currentRoute.name == 'Home'){
      let currentDate = new Date()
      const timestamp = currentDate.getTime()
      console.log("Obtaining ships " + timestamp)
      let res = await getGeoserverLayer(url)
      await dispatch('alerts/GetImpactZoneStatistics',{},{ root: true })
      dispatch('changeShipsData', res.data.features)
    }
    setTimeout(() => {
      dispatch('getShips', url)
    }, 60000);

  }
};
const getters = {
  showLayers: (state) => state.showLayers,
  typeOfShip: (state) => state.typeOfShip,
  typeOfsighting: (state) => state.typeOfsighting,
  shipsData: (state) => state.shipsData,
  sightingsData: (state) => state.sightingsData,
  selectedShipTypes: (state) => state.selectedShipTypes,
  selectedSightingTypes: (state) => state.selectedSightingTypes,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
