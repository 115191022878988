import Vue from "vue";

const toaster_success = (msg) => {
  return Vue.$toast.open({
    message: msg,
    type: "success",
    position: "top",
    pauseOnHover: true,
    dismissible: true,
    duration: 5000,
  });
};

const toaster_error = (msg) => {
  return Vue.$toast.open({
    message: msg,
    type: "error",
    position: "top",
    pauseOnHover: true,
    dismissible: true,
    duration: 5000,
  });
};

const toaster_info = (msg) => {
  return Vue.$toast.open({
    message: msg,
    type: "info",
    position: "top",
    pauseOnHover: true,
    dismissible: true,
    duration: 5000,
  });
};

export { toaster_success, toaster_error, toaster_info };


export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

export function fliterDates(year,month,days_month) {
  if (!month){
    var year_start = year + '-01-01'
    var year_fin = year + '-12-31'
    return "start_date > '" + year_start + "' AND start_date < '" + year_fin + "'" 
  }else{
    var date_start = year + '-' + month + '-01'
    var date_fin = year + '-' + month + '-' + days_month
    return "start_date > '" + date_start + "' AND start_date < '" + date_fin + "'" 
  }
}

export function generateArrayOfYears() {
  var max = new Date().getFullYear()
  var min = 2016
  var years = []

  for (var i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}

export function generarColorDesdeString(str) {
  // Función de hash simple para convertir el string en un número
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colorHexadecimal = '#' + (hash & 0x00FFFFFF).toString(16).toUpperCase().slice(3);
  return colorHexadecimal;
}

export function getUniqueShipTypes(data){
  let allShipTypes = []
  let uniqueShipTypes = []
  data.forEach(ship => {
    let shipType = {
      'id':ship.properties.tipo_buque,
      'ship_type':ship.properties.tipo_buq_1,
      'ship_3d_id':ship.properties.model_3d_i,
    }
    if(!allShipTypes.includes(shipType.ship_type)){
      allShipTypes.push(shipType.ship_type)
      uniqueShipTypes.push(shipType)
    }
  })
  uniqueShipTypes.forEach(obj => {
    obj.ship_type = obj.ship_type.replace(/_/g, ' ')
  });
  return uniqueShipTypes
}

export function getUniqueSightingsTypes(data){
  data.sort(function (a, b) {
    var sizeA = a.properties['GROUP SIZE'] || 1; 
    var sizeB = b.properties['GROUP SIZE'] || 1; 
    return sizeB - sizeA; 
  });

  let allSightingsTypes = []
  let uniqueSightingsTypes = []
  let index = 1

  data.forEach(sighting => {
    if(!allSightingsTypes.includes(sighting.properties.SPECIES)){
      let SightingType = {
        'id':index,
        'specie':sighting.properties.SPECIES,
        'color': generarColorDesdeString(sighting.properties.SPECIES)
      }
      index ++
      uniqueSightingsTypes.push(SightingType)
      allSightingsTypes.push(sighting.properties.SPECIES)
    }
  })
  return uniqueSightingsTypes
}
