<template>
  <div class="alerts-scroll-container">
    <div class="alerts-filter">
      <input type="text" class="search-input" placeholder="Filter alerts..." v-model="searchText" @keyup="filter()"/>
      <span class="material-symbols-outlined search-icon">search</span>
    </div>

    <div class="separator-body-bottom"></div>
    <div class="separator-body-top"></div>

    <div v-if="isLoading" class="message-history">Loading history...</div>
    <div v-else-if="alerts.length == 0" class="message-history">No historical alerts</div>

    <div class="alerts-scroll-view" v-bind:class="{ 'visible': !isLoading }">
      <div v-for="alert in alertsFiltered" :key="alert.name">
        <AlertItem :alert="alert"/>
      </div>
    </div>

  </div>
</template>

    
<script>
import { getData } from '@/services/api.js'
import AlertItem from '@/components/PanelsMap/AlertsPanelTabs/AlertItem.vue'

export default {
  name: 'AlertsHistory',
  components: {
    AlertItem
  },
  data() {
    return {
      isLoading: true,
      alerts: [],
      alertsFiltered: [],
      searchText: ''
    };
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    getHistory: function() {
      getData('alerts/historical/user')
      .then ( res => {
        this.isLoading = false
        this.alerts = res.data
        this.filter()
      })
    },
    filter: function() {
      if (!this.isLoading) {
        this.alertsFiltered = []
        this.alerts.forEach(alert => {
          if (this.searchText){
            if (alert.rule__name.toUpperCase().includes(this.searchText.toUpperCase())) {
                this.alertsFiltered.push(alert)
            }
          }else{
            this.alertsFiltered.push(alert)
          }

        })       
      }    
    }
  }
};
</script>

<style scoped>
  .alerts-scroll-container {
    color: white;
    bottom: 55px;
    position: absolute;
    width: 100%;
    top: 75px;
  }
  .alerts-scroll-view {
    overflow: auto;
    height: 100%;
    opacity: 0;
  }
  .visible {
    opacity: 1;
  }

  .message-history {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 0.9rem;
  }

  /* **********   FILTER   ************ */
  .alerts-filter {
    height: 40px;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .search-input {
    padding: 5px 15px 5px 35px;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
    border-radius: 25px;
    width: 90%;
    height: 26px;
    font-size: 0.8rem;
    color: #013642;
  }

  .search-icon {
    position: absolute;
    color: #aaa;
    left: 8%;
  }

  /* **********   DIV SEPARATORS   ************ */
  .separator-body-top {
    background-color: #00849c;
    height: 2px;
    width: 100%;
  }
  
  .separator-body-bottom {
    background-color: #005d71;
    height: 2px;
    width: 100%; 
  }

  .separator-header-bottom {
    background-color: #013642;
    height: 2px;
    width: 100%;
  }

  .material-symbols-outlined {
    font-size: 20px;
    opacity: 1;
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
  }

   /* **********   ANIMATIONS   ************ */
  .alerts-scroll-view {
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
</style>
  