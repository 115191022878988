import { getData, postData,deleteData,putData } from "@/services/api.js";
import {
  toaster_success,
  toaster_error,
  toaster_info,
} from "@/services/helper.js";

const state = () => ({
    alerts:[],
    alert_types:[
      {
        alert_type_id: 0,
        alert_type_name:'Ship speed'
      },
      {
        alert_type_id: 1,
        alert_type_name:'Ships count'
      }
    ],

    ships_in_impact_zone_sum: 0,
    ships_outside_zone_impact_sum: 0,
    // statistics:{
    //   zone_impact_info: {},
    //   alerts: {},
    // },
    statistics: undefined,
    config_list: [],


});
  
  const mutations = {
    SetAlerts(state, payload) {
      state.alerts = payload;
    },
    SetStatistics(state, payload) {
      state.statistics = payload;
    },
    SetConfigList(state, payload) {
      state.config_list = payload;
    },
  };
  
  const actions = {
    ChangeAlerts({ commit }, alertData) {
      commit('SetAlerts', alertData);
    },
    ChangeShipCount({ commit }, data) {
      commit('SetStatistics', data);
    },
    async GetAlerts({ dispatch }) {
      // var resAlerts = await getData('alerts/config/')
      // var listAlerts = resAlerts.data.alerts
      // dispatch('ChangeAlerts', listAlerts)
    },
    async GetAlertsConfig({ commit }, data) {
      let res = await getData('alerts/rules/user/')
      commit('SetConfigList',res.data)
    },
    async PostAlertsConfig({ dispatch }, data) {
      await postData(data.url, data.formData)
      dispatch('GetAlertsConfig')
    },
    async PutAlertsConfig({ dispatch }, data) {
      await putData(data.url,data.id, data.formData)
    },
    async DeleteAlertsConfig({ dispatch }, data) {
      await deleteData(data.url, data.id)
      dispatch('GetAlertsConfig')
    },
    async GetImpactZoneStatistics({ commit, getters }) {
      let res_statistics = await getData('alerts/ship-impact-zone/')
      if(getters.statistics != undefined){
        if(res_statistics.data.alerts.length > getters.statistics.alerts.length){
          toaster_error("New Alert!")
        }
      }
      commit('SetStatistics',res_statistics.data)
    },
  };
  
  const getters = {
    alerts: (state) => state.alerts,
    alertType: (state) => state.alert_types,
    statistics: (state) => state.statistics,
    configList: (state) => state.config_list
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };