<template>
  <div class="alerts-scroll-container">
    <div class="my-alerts">
      <label class="list-group-item" >
        <input class="me-1" type="checkbox"  v-model="showMyAlerts" @change="toggleMyAlerts()" />
        My Alerts 
      </label>
    </div>

    <div class="separator-body-bottom"></div>
    <div class="separator-body-top"></div>

    <div style="color: white;">
      <div v-if="statistics.alerts.length == 0"  class="no-alerts">No active alerts</div>
        <div v-else v-for="alert in alertsFiltered" :key="alert.id" class="alert-realtime">
        <AlertItem :alert="alert"/>
      </div>
    </div>  
  </div>  
</template>

    
<script>
import { getData } from '@/services/api.js'
import { mapGetters } from 'vuex'
import AlertItem from '@/components/PanelsMap/AlertsPanelTabs/AlertItem.vue'
export default {
  name: 'AlertsRealTime',
  data() {
    return {
      alerts: [],
      alertsFiltered: [],
      showMyAlerts: false,
    };
  },
  components: {
    AlertItem
  },

  computed: { 
    ...mapGetters(
      'alerts', {
        statistics: 'statistics',
        
      },
    ),

    ...mapGetters(
      'user', {
        isLoggedIn: 'isLoggedIn',
        username: 'username',
        first_name: 'first_name',
        last_name: 'last_name',
      }
    ),

  },

  mounted() {
    this.toggleMyAlerts()
  },


  methods: {
    toggleMyAlerts: function (){
      this.alertsFiltered= []
      if (!this.showMyAlerts) {
        this.alertsFiltered= this.statistics.alerts
      }
      else{
        this.statistics.alerts.forEach(alert => {
          if (alert.username == this.username){
            this.alertsFiltered.push(alert)
          }
        })
      }
    }
  }
};
</script>

<style scoped>
.alerts-scroll-container {
  color: white;
  bottom: 55px;
  position: absolute;
  width: 100%;
  top: 75px;
  overflow-y: scroll;
  
}

.alerts-scroll-container::-webkit-scrollbar-thumb {
  background-color: #005d71; 
  border-radius: 10px; 
}

.alerts-scroll-container::-webkit-scrollbar {
  height: 100px;
  width: 9px;
}
/* **********   Real Time Alerts   ************ */
.no-alerts {
  
  display: flex;
  height: 100%;
  font-size: 0.9rem;
  margin-top: 170px;
}

.alert-realtime{
  width: 100%;
  display: flex;
  
}

.alert-label{
  margin-left: 20px;
}

/* **********   Chechbox My Alerts   ************ */
.my-alerts{
  width: 100%;
  display:flex;
  justify-content: center;
  margin-top:10px;
  margin-bottom: 8px;
}

.list-group-item{
  display:flex;
  justify-content: center;
  
}

/* **********   DIV SEPARATORS   ************ */
.separator-body-top {
  background-color: #00849c;
  height: 2px;
  width: 100%;
}

.separator-body-bottom {
  background-color: #005d71;
  height: 2px;
  width: 100%; 
}
</style>
  