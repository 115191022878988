<template>
    <div class="container-submenu">
        <div style="flex: 1;"></div>
        <div class="submenu">
            <img src="@/assets/icons/Submenu_wave.svg" class="grafico-submenu" alt="icono">
            <div class="submenu-content">
                <div v-if="statistics != undefined" class="ship-count">Ships inside Impact Zone: <span style="color: white; font-weight: bold;">{{ statistics.zone_impact_info.ships_in_impact_zone_sum }}</span> / {{ statistics.zone_impact_info.ships_outside_zone_impact_sum }}</div>
                <span class="material-symbols-outlined" v-bind:class="{ 'icon-selected': mode == '3D' }" @click="navigate()" b-tooltip.hover title="Toggle 3D map view">deployed_code</span>
                <span class="material-symbols-outlined" v-bind:class="{ 'icon-selected': showFiltersWindow }" @click="toggleWindows('filters')" b-tooltip.hover title="Map filters">tune</span>
                <span class="material-symbols-outlined" v-bind:class="{ 'icon-selected': showAlertsWindow }" @click="toggleWindows('alerts')" b-tooltip.hover title="Alerts">notifications</span>               
            </div>
            
        </div>
        <span v-if="statistics != undefined" style="z-index:10;">
            <div v-if="statistics.alerts.length != 0 && statistics.alerts.length <= 99"  id="circle-icon">{{statistics.alerts.length}}</div>
            <div v-else-if="statistics.alerts.length > 99" id="circle-icon">99</div>
        </span>

    </div>   
</template>
    
<script>
import { mapGetters } from 'vuex'
export default {
    name: "SubMenu",
    
    data() {
        return {
            mode:""
        }
    },
    computed: { 
    ...mapGetters('ui',{
        showFiltersWindow:'showFiltersWindow',
        showAlertsWindow:'showAlertsWindow',
    }),
    ...mapGetters('alerts', {
        statistics: 'statistics',
    })
  },
    mounted() {
        if (this.$route.path == '/'){
            this.mode = '2D'
        }
        else{
            this.mode = '3D'
        }
    },

    methods: {
        navigate: function(){
            if (this.mode == '2D'){
                this.$router.push('/monitor')
            }
            else{
                this.$router.push('/')
            }
        },
        toggleWindows: function(windowName) {
            if(windowName == 'filters') {
                this.$store.dispatch('ui/changeFiltersWindow', !this.showFiltersWindow)
            }
            else if(windowName == 'alerts') {
                this.$store.dispatch('ui/changeAlertsWindow', !this.showAlertsWindow)
            }
        }
    }
};

</script>

<style scoped lang="scss">

.container-submenu {
    position: absolute;
    top: $nav-height;
    right: 0px;
    left: 0px;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    margin: 0 !important;
    padding: 0 !important;
}
.submenu {
    background-color: #004054;
    color: white;
    min-width: 200px;
    height: 30px;
    display: flex;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;
    right: 0;
}
.submenu-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.grafico-submenu {
    height: 100%;
    position: absolute;
    left: -62px;
    z-index: -10;
    filter: brightness(0) saturate(100%) invert(15%) sepia(57%) saturate(2406%) hue-rotate(170deg) brightness(93%) contrast(101%);
}


.material-symbols-outlined {
    font-size: 20px;
    opacity: 0.65;
    margin-right: 20px;
    cursor: pointer;
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

.material-symbols-outlined:hover {
    // color:#ffc866;
    opacity: 1;
}

//Number of alerts icon
#circle-icon{
    width: 13px;
    height: 13px;
    background-color:red;
    border-radius: 10000px;
    z-index: 1000;
    margin-right: 16px;
    margin-top:2px;
    text-align: center;
    color: white;
    font-size: 9px;
    
    
}

.icon-selected {
    color:#fff;
    opacity: 1;
    font-size: 22px;
    font-weight: bold;
}

.ship-count {
    padding-right: 30px;
    margin-right: 30px;
    font-size: 0.8rem;
    border-right: 1px solid rgba(255,255,255,0.8)
}

/* **********   ANIMATIONS   ************ */
.material-symbols-outlined {
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
</style>
