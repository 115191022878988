import { render, staticRenderFns } from "./AlertsHistory.vue?vue&type=template&id=24bcdca2&scoped=true"
import script from "./AlertsHistory.vue?vue&type=script&lang=js"
export * from "./AlertsHistory.vue?vue&type=script&lang=js"
import style0 from "./AlertsHistory.vue?vue&type=style&index=0&id=24bcdca2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24bcdca2",
  null
  
)

export default component.exports