<template>
  <div id="panel-map-layer" class="panel-maps-layers col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1">
    <button class="btn btn-layer-list  d-md-none " data-bs-toggle="collapse" data-bs-target="#layers-list"  aria-controls="layerList" aria-expanded="false" aria-label="Toggle layerList">
      <img  class="icon-layer-list" :src="require('@/assets/icons/menu.svg')"   />
    </button>  
    <div id="layers-list" class="panel-container  collapse d-md-block">
        <div class="panel-map-header">
          <span id="layers-icon" class="material-symbols-outlined">layers</span>
          <label class="layers-title">Map Layers</label>
        </div>
        <div class="border-main-title-map-layers3"></div>
        <div class="border-main-title-map-layers2"></div>
      <div class="item-list" >
        <label class="label"> Ships </label>
        <div class="list-group mt-1">
          <label class="list-group-item" >
            <input class="me-1" type="checkbox" v-model="showLayers_ships"  checked/>
            Ships
          </label>
          <label class="list-group-item" >
            <input class="me-1" type="checkbox"  v-model="showLayers_routes" />
            Routes
          </label>
        </div>
      </div>
      <div class="border-main-title-map-layers"></div>
      <div class="border-main-title-map-layers2"></div>
      <div class="item-list mt-3" >
        <label class="label"> Cetacean </label>
        <div class="list-group mt-1">
          <!-- <label class="list-group-item" >
            <input class="me-1" type="checkbox" value=1 disabled />
            Habitat
          </label> -->
          <label class="list-group-item" >
            <input class="me-1" type="checkbox"  v-model="showLayers_Sightings" />
            Sightings
          </label>
        </div>
      </div> 
      <div class="border-main-title-map-layers"></div>
      <div class="border-main-title-map-layers2"></div>
      
      <div class="item-list mt-3" >
        <label class="label"> Zones </label>
        <div class="list-group mt-1">
          <label class="list-group-item" >
            <input class="me-1" type="checkbox" v-model="showLayers_controlZone" />
            Control zone
          </label>
          <label class="list-group-item" >
            <input class="me-1" type="checkbox"  v-model="showLayers_impactZone" />
            Impact zone 
          </label>
        </div>
      </div>
      <!-- <div class="item-list mt-3" >
        <label class="label"> Risk maps </label>
        <div class="list-group mt-1">
          <label class="list-group-item" >
            <input class="me-1" type="checkbox" disabled />
            Risk of impact
          </label>
          <label class="list-group-item" >
            <input class="me-1" type="checkbox" disabled />
            Sound pressure
          </label>
        </div>
      </div> -->
    </div>
    <!-- {{ showLayers }} -->
  </div>
</template>

    
  <script>
import { mapGetters } from 'vuex'
import { getDataAIS } from '@/services/api'

  export default {
    data() {
      return {
        //selectedShipTypes: []
      };
    },

    computed: { 
      ...mapGetters("ais",{
        typeOfShip: 'typeOfShip',
        showLayers: 'showLayers',
        //selectedShipTypes: 'selectedShipTypes',
      }),
      showLayers_ships: {
        set (value) { this.$store.dispatch('ais/changeShowLayers', ['ships',value]) },
        get () { return this.$store.state.ais.showLayers.ships }
      },
      showLayers_routes: {
        set (value) { this.$store.dispatch('ais/changeShowLayers', ['routes',value]) },
        get () { return this.$store.state.ais.showLayers.routes }
      },
      showLayers_Sightings: {
        set (value) { this.$store.dispatch('ais/changeShowLayers', ['sightings',value]) },
        get () { return this.$store.state.ais.showLayers.sightings }
      },
      showLayers_controlZone: {
        set (value) { this.$store.dispatch('ais/changeShowLayers', ['controlZone',value]) },
        get () { return this.$store.state.ais.showLayers.controlZone }
      },
      showLayers_impactZone: {
        set (value) { this.$store.dispatch('ais/changeShowLayers', ['impactZone',value]) },
        get () { return this.$store.state.ais.showLayers.impactZone }
      },
      // selectedShipTypes: {
      //   set (value) { this.$store.dispatch('ais/changeSelectedShipTypes', value) },
      //   get () { return this.$store.state.ais.selectedShipTypes }
      // },
    },
    mounted() {

    },
    methods: {
    },

    watch: {
      
    },

  };
  </script>
  
  <style scoped>
  .panel-maps-layers {
    width: 260px;
    /* height: 330px; */
    position: absolute;
    top: 110px; 
    /* right: 345px;  */
    left: 30px;
    background-color: #017294;
    color: #565656 !important;
    padding: 10px; 
    z-index: 900;
    border-radius: 12px;
    padding: 0;
    min-width: 170px;
    /* overflow: hidden; */
    /* min-height: 200px; */
  }

  .label{
    color: #00f2da;
    position:relative;
    bottom: 8px;
    
  }
  .panel-container{
    overflow: auto;
    width: 100%; 
  }


  .centered-content {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    height: 100%; /* Asegura que el contenedor ocupe toda la altura del botón */   
  }

  .list-group-item {
    border: none;
    border-end-start-radius: 0px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 0.85em;
    background-color: #017294;
    color:white;  
    position:relative;
    bottom: 8px;
  } 
  .list-group-item.active{
    background-color:  white;
    border-color:  white;
  }
  .last-button {
    border-right: none;
  }

  .panel-ships-filter-title {
    font-size: 1.0em;
    color: white;
    margin-left: 10px;
    margin-top:9px;   
  }
  .panel-link{
    display: block;
    color:#b6b6b6; 
    text-decoration: none;
    width:100%;
    margin-bottom: 5px;
    text-align: right;
    cursor: pointer;
  }

/*Divs separadores*/
  .border-main-title-map-layers2{
    background-color: #00849c;
    height: 2px;
    width: 100%;
  }
  
  .border-main-title-map-layers {
    background-color: #005d71;
    height: 2px;
    width: 100%; 
  }

  .border-main-title-map-layers3 {
    background-color: #013642;
    height: 2px;
    width: 100%;
  }

/*Icono del map layer*/
  #layers-icon {
    margin-left: 25px;
    color:#00f2da;
    font-size: 1.5rem;
  }
  .layers-title {
    color: white;
    margin-left: 10px;
    font-size: 0.8rem;
    font-weight: 600;
  }

 /*Elementos del map layer*/ 
  .item-list{  
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom:10px;
  }


  /*Header del map layer*/
  .panel-map-header{
    width:100%;
    height: 36px;
    background-color: #004054;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    display:flex;
    align-items: center;
  }
  </style>
  