<template>
  <div class="panel-alerts">
    <div class="panel-container">
      
      <!-- HEADER -->
      <div class="panel-map-header">
        <span class="material-symbols-outlined panel-icon">notifications</span>
        <label class="panel-title">Alerts</label>
      </div>

      <!-- SEPARATOR -->
      <div class="separator-header-bottom"></div>
      <div class="separator-body-top"></div>

      <!-- TABS -->
      <div class="item-list" >
        <div class="alert-tabs">
          <div class="alert-tab" @click="changeTab(0)" v-bind:class="{ 'tab-selected': tab_selected == 0 }"><span class="material-symbols-outlined tab-icon" v-bind:class="{ 'tab-icon-selected': tab_selected == 0 }">timer</span><span class="tab-text">Real Time</span></div>
          <div class="alert-tab" @click="changeTab(1)" v-bind:class="{ 'tab-selected': tab_selected == 1 }"><span class="material-symbols-outlined tab-icon" v-bind:class="{ 'tab-icon-selected': tab_selected == 1 }">calendar_today</span><span class="tab-text">History</span></div>
        </div>
      </div> 

      <!-- SEPARATOR -->
      <div class="border-main-title-map-layers"></div>
      <div class="border-main-title-map-layers2"></div>

      <!-- CONTENT -->
      <AlertsRealTime v-if="tab_selected == 0" />
      <AlertsHistory v-else />
    </div>
  </div>
</template>

    
<script>
import AlertsRealTime from '@/components/PanelsMap/AlertsPanelTabs/AlertsRealTime.vue'
import AlertsHistory from '@/components/PanelsMap/AlertsPanelTabs/AlertsHistory.vue'

  export default {
    name: 'PanelAlerts',
    components: {
      AlertsRealTime,
      AlertsHistory
    },
    data() {
      return {
        tab_selected: 0
      };
    },
    methods: {
      changeTab: function(tab) {
        this.tab_selected = tab
      }
    }
  };
  </script>
  

  <style scoped>
  /* **********   GENERAL   ************ */
  .panel-alerts {
    position: absolute;
    right: 30px; 
    width: 20%; 
    height: 50%;
    background-color: white;
    background-color: #017294;
    color: #565656 !important;
    padding: 0 !important; 
    z-index: 1000;
    border-radius: 9px;
    min-width: 220px;
  }
  .panel-container{
    padding-bottom: 15px;
    /* overflow: auto; */
    height: 90%;
  }

  /* **********   HEADER   ************ */
  .panel-map-header{
    width:100%;
    height: 36px;
    background-color: #004054;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    display:flex;
    align-items: center;
  }
  .panel-icon {
    margin-left: 25px;
    color:#00f2da;
  }
  .material-symbols-outlined {
    font-size: 20px;
    opacity: 1;
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
  }
  .panel-title {
    color: white;
    margin-left: 10px;
    font-size: 0.8rem;
    font-weight: 600;
  }

  /* **********   DIV SEPARATORS   ************ */
  .separator-body-top{
    background-color: #00849c;
    height: 2px;
    width: 100%;
  }
  
  .separator-body-bottom {
    background-color: #005d71;
    height: 2px;
    width: 100%; 
  }

  .separator-header-bottom {
    background-color: #013642;
    height: 2px;
    width: 100%;
  }

  /* **********   TABS   ************ */
  .alert-tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30px;
  }
  .alert-tab {
    flex: 1;
    background-color: #00607d;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a6c2db;
    cursor: pointer;

  }
  .tab-selected {
    background-color: #017294;
    color: white;
  }
  .tab-icon {
    color: #a6c2db;
    font-size: 15px;
  }
  .tab-icon-selected {
    color: white;
  }
  .tab-text {
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 10px;
  }

  /* **********   ANIMATIONS   ************ */
  .material-symbols-outlined, .alert-tabs, .alert-tab, .tab-text{
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  </style>
  