<template>
  <div class="alerta-item-container" v-bind:class="{ 'warning-bg': alert.status == 'warning' }">
    <div class="alert-title">
      <span v-if="alert.status != 'warning'" class="material-symbols-outlined alert-item-icon">emergency_home</span>
      <span v-else class="material-symbols-outlined warning-item-icon">warning</span>
      <span v-bind:class="{ 'status-warning': alert.status == 'warning' }">{{ name }}</span>
    </div>
    <div class="alert-small-text">{{ subData }}</div>
    <div style="height: 6px;"></div>
    <div class="alert-data">{{ type_text }}</div>
    <div class="alert-data-value">
      <span v-if="alert.type == 0" class="material-symbols-outlined type-item-icon">fast_forward</span>
      <span v-else-if="alert.type == 1" class="material-symbols-outlined type-item-icon">sailing</span>
      {{ alert.value }}
    </div>
  </div>
</template>

    
<script>
import { mapGetters } from 'vuex'
import { getData } from '@/services/api.js'

export default {
  name: 'AlertItem',
  props:{
    alert: {}
  },
  data() {
    return {
      name: '',
      subData: '',
      type_text: ''
    };
  },

  mounted() {

    if (!this.alert.name) 
      this.name = this.alert.rule__name
    else
      this.name = this.alert.name

    if (!this.alert.date_time_init) 
      this.subData = this.alert.username
    else{
      let dateTimeStr = this.alert.date_time_init + 'Z'
      let dateTimeObject = new Date(dateTimeStr)
      this.subData = dateTimeObject.toDateString() + ' - ' + dateTimeObject.toLocaleTimeString()
    }

    if(this.alert.type == 0 || this.alert.rule__type == 0)
      this.type_text = 'These ships exceded the speed limit (mmsi):'
    else if(this.alert.type == 1 || this.alert.rule__type == 1)
      this.type_text = 'Too many ships inside Impact Zone:'
    
  },
  methods: {

  }
};
</script>

<style scoped>
  .alerta-item-container {
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    background-color: rgba(255, 0, 0, 0.55)
  }
  .warning-bg {
    background-color: rgba(255, 255, 255, 0.15);
  }
  .alert-title {
    color: #ffbaba;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  .alert-small-text {
    font-size: 0.6rem;
  }
  .alert-data {
    font-size: 0.8rem;
  }
  .alert-data-value {
    font-size: 0.95rem;
    font-weight: 600;
  }
  .status-warning {
    color: #ffe300;
  }
  .alert-item-icon {
    color: #ffbaba;
    font-size: 15px;
    margin-right: 10px;
  }
  .warning-item-icon {
    color: #ffe300;
    font-size: 15px;
    margin-right: 10px;
  }
  .type-item-icon {
    color: white;
    font-size: 12px;
    margin-right: 8px;
  }
  .material-symbols-outlined {
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
  }
</style>
  