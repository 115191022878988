<template>
  <div class="navbar-container">
    <nav class="navbar navbar-life navbar-expand-lg bg-body-tertiary navbar-dark bg-dark">
      <div class="container-fluid navbar-data">
        <a class="navbar-brand" href="#"><img :src="require(`@/assets/img/LPS_Logo.svg`)" style="width: auto; height: 38px; margin-right: 20px; margin-left: 20px;"></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto ">
            <li class="nav-item pt-2">
              <router-link  class="nav-link" v-bind:class="{ 'current-path': $route.path == '/' ||  $route.path == '/monitor'}" tag="li" to="/" exact>
                Live Map
              </router-link>
            </li>
            <!-- <li class="nav-item pt-2">
              <router-link  class="nav-link" tag="li" to="/monitor" exact>
                Monitor 3D
              </router-link>
            </li> -->
            <li class="nav-item pt-2">
              <a class="nav-link" href="#">Predictive scenarios</a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link "  href="#">Signal processing</a>
            </li>
            <li class="nav-item pt-2 me-5">
              <router-link class="nav-link" v-bind:class="{ 'current-path': $route.path == '/alertssettings' }" tag="li" to="/alertssettings" exact>
                Alerts Settings
              </router-link>
              
            </li>
            <li v-if="!this.$keycloak.authenticated" class="nav-item p-2 ms-5">
              <a  class="nav-link "  href="#">Login</a>
            </li> 
            <li v-else class="nav-item dropdown nav-item  p-2 me-5">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{first_name}} {{last_name}}
              </a>
              <ul class="dropdown-menu" style="z-index: 100000;">
                <li><a class="dropdown-item" v-on:click="logout" href="#">Sign Out</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "Navbar",
  components:{
  },
  props: {
    title: String
  },
  data(){
    return {
      showINROptions: false,
      showINROptionsTimer: null
    }
  },
  computed: { 
    ...mapGetters("user",{
      isLoggedIn: 'isLoggedIn',
      username: 'username',
      first_name: 'first_name',
      last_name: 'last_name',
      userType: 'user_type',
    }),
     isINRMed(){
        return this.$route.name === "INR-Med"
      },
      isHome(){
        return this.$route.name === "Home"
      }
  },
  // watch: {
  //   showINROptions: function () {
  //     if(this.showINROptions) {
  //       this.showModal()
  //     }
  //     else {
  //       this.hideModal()
  //     }
  //   },
  // },
  methods:{
    showModal: function () {
      if (this.showINROptionsTimer != null) {
        clearTimeout(this.showINROptionsTimer)
      }
      this.showINROptions = true
    },
    hideModal: function () {
      if (this.showINROptionsTimer != null) {
        clearTimeout(this.showINROptionsTimer)
      }
      let globalThis = this   
      this.showINROptionsTimer = setTimeout(() => {
        globalThis.showINROptions = false;
      }, 150);
    },
    goTo: function (url) {
      this.showINROptions = false;
      this.$router.push({path: url})
    },
    logout: function() {
      this.$store
        .dispatch("user/logout")
        .then(() => this.$router.push("/"))
        .catch(err => console.log(err));
    }
  }
};
</script>

<style scoped lang="scss">

.navbar-life {
  background-image: linear-gradient(to right, #007293, #00a89d);
  color: white !important;
  height: $nav-height !important;
  z-index: 1000;
  position: absolute;
  width: 100%;
  //min-height:  $nav-height !important;
}
.navbar-data{
  background-image: linear-gradient(to right, #007293, #00a89d);
}
.navbar-container {
  min-height: 110px;
}
.current-path {
  color: #00ffe4;
  opacity: 1;
  font-weight: 600;
}
</style>
