import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Monitor from "../views/Monitor.vue";
import AlertsSettings from "../views/AlertsSettings.vue";
import SilentCheckSSO from "../views/SilentCheckSSO.vue";
import About from "../views/About.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      requiresAuth: true,
    },
    component: Home,
    // meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: "/monitor",
    name: "Monitor",
    meta: {
      requiresAuth: true,
    },
    component: Monitor,
    // meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/alertssettings",
    name: "AlertsSettings",
    component: AlertsSettings,
    meta: {
      requiresAuth: true,
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresNotAuth)) {
//     if (!store.getters["user/isLoggedIn"]) {
//       next();
//       return;
//     }
//     next("/user-panel");
//   } else {
//     next();
//   }
// });

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (store.getters["user/isLoggedIn"]) {
//       next();
//       return;
//     }
//     next("login");
//   } else {
//     next();
//   }
// });

export default router;
