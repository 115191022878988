<template>
  <div class="container-fluid m-0 p-0">
    <div id="map" class="map-container"></div>
    <div class="pt-2 row h-100">
      <SubMenu />
      <PanelMapLayers />
      <PanelShipsFilter v-if="showFiltersWindow"/>   
      <PanelAlerts v-if="showAlertsWindow"/>
      
    </div>
  </div>
</template>

<script>
import { getGeoserverLayer } from '@/services/api'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import 'leaflet-rotatedmarker'
import SubMenu from '@/components/Map/SubMenu.vue'
import PanelShipsFilter from '@/components/PanelsMap/PanelShipsFilter.vue'
import PanelMapLayers from '@/components/PanelsMap/PanelMapLayers.vue'
import PanelAlerts from '@/components/PanelsMap/PanelAlerts.vue'
import {generarColorDesdeString} from "@/services/helper.js";
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    PanelShipsFilter,
    PanelMapLayers,
    PanelAlerts,
    SubMenu,
  },
  data() {
    return {
      map: null,
      shipMarkers: L.layerGroup(), 
      sightingsMarkers: L.layerGroup(), 
      typesOfShips: [], // Aquí se almacenan los tipos de embarcaciones únicos
      shipsDataWithMarker: [],
      SightingsDataWithMarker: [],
      controlZoneLayer: '',
      impactZoneLayer: '',
      impactPolygon: '',
      routesLayer: '',
      routesFeatures: '',
      routesFeaturesFilter: '',
      lat: 10,
      lon: -1,
      style_danger:''
    }
  },
  computed: { 
    ...mapGetters("ais",{
      shipsData: 'shipsData',
      sightingsData: 'sightingsData',
      selectedShipTypes: 'selectedShipTypes',
      selectedSightingTypes:'selectedSightingTypes',
      typeOfsighting: 'typeOfsighting',
      showLayers: 'showLayers',
    }),
    ...mapGetters('alerts',{
      config:'config',
      statistics:'statistics',
    }),
    ...mapGetters('ui',{
      showFiltersWindow:'showFiltersWindow',
      showAlertsWindow:'showAlertsWindow',
    })
  },
  watch: {
    selectedShipTypes: {
      handler(newselectedShipTypes, oldselectedShipTypes) {
        this.handleFilterByShipType() // Llama a la función que deseas ejecutar
        this.handleFilterByShipTypeRoutes()
      },
      deep: true, // Vigila cambios profundos en el objeto (necesario si selectedShipTypes es un objeto)
    },
    selectedSightingTypes: {
      handler(newselectedShipTypes, oldselectedShipTypes) {
        this.handleFilterBySightingType() // Llama a la función que deseas ejecutar
      },
      deep: true, // Vigila cambios profundos en el objeto (necesario si selectedShipTypes es un objeto)
    },
    showLayers: {
      handler(newselectedShipTypes, oldselectedShipTypes) {
        this.handleShowLayers()
      },
      deep: true, // Vigila cambios profundos en el objeto (necesario si selectedShipTypes es un objeto)
    },
    shipsData: {
      handler(newShipsData, oldShipsData) {
        this.loadShipsFromGeoserver()
      },
      deep: true, // Vigila cambios profundos en el objeto (necesario si selectedShipTypes es un objeto)
    },
  },
  mounted() {
    this.setBaseMap()
    this.loadImpactZoneFromGeoserver()
    this.loadControlZoneFromGeoserver()
    this.loadRoutesFromGeoserver()
    //this.loadShipsFromGeoserver()
    // this.$store.dispatch('ais/getShips', process.env.VUE_APP_GEOSERVER_AIS_LAYER)
    this.loadSightingsFromGeoserver()

    //Remove Leaflet Attribution
    const attribution = document.getElementsByClassName("leaflet-control-attribution");
    for (let i = 0; i < attribution.length; i++) {
      attribution[i].remove()
    }

    // Load Leaflet-pip. Permite usar operacioens para buscar dentro de un polígono. Finalmente no se usa, se hace en el backend.
    if (document.getElementById('leaflet-pip')) return; // was already loaded
    var scriptTag = document.createElement("script");
    scriptTag.src = "scripts/wise-leaflet-pip.js";
    scriptTag.id = "leaflet-pip";
    document.getElementsByTagName('head')[0].appendChild(scriptTag);

  },
  methods: {
    setBaseMap: function () {
      this.map = L.map('map', { zoomControl: false }).setView([37.5, -0.894302], 11)
      //https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}
      //https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}
      L.tileLayer(
          'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
          {
            attribution:
              '&copy; <a href="https://www.ctnaval.com/">Centro Tecnológico Naval y del Mar</a>',
          }
      ).addTo(this.map)
      L.control.scale({ imperial: false }).addTo(this.map)
    },  

    loadShipsFromGeoserver: async function () {
        this.shipMarkersArray = [] // Se resetea el array de marcadores
        // this.map.removeLayer(this.shipMarkers)
        this.shipMarkers.clearLayers()
        this.shipsDataWithMarker = []

        this.style_danger = ''
        let ships_exceeds_maximum_speed_total_warning = []
        let ships_exceeds_maximum_speed_total_alert = []

        for (let index = 0; index < this.statistics.alerts.length; index++) {
          if (this.statistics.alerts[index].type != 0) continue

          let ships_exceeds_maximum_speed = this.statistics.alerts[index].value
          ships_exceeds_maximum_speed = ships_exceeds_maximum_speed.split(',').map(Number)
          if (this.statistics.alerts[index].status == 'alert')
            ships_exceeds_maximum_speed_total_alert = ships_exceeds_maximum_speed_total_alert.concat(ships_exceeds_maximum_speed) 
          else
            ships_exceeds_maximum_speed_total_warning = ships_exceeds_maximum_speed_total_warning.concat(ships_exceeds_maximum_speed) 
        }
        L.geoJson(this.shipsData, {
            pointToLayer: (feature, latlng) => {
            
              
              let ship_image_name =  feature.properties.model_3d_i + '.png'
              if (ships_exceeds_maximum_speed_total_alert.includes(feature.properties.mmsi)) {
                this.style_danger = ' style="color: red";'
                ship_image_name =  feature.properties.model_3d_i + '_red.png'
              }else if (ships_exceeds_maximum_speed_total_warning.includes(feature.properties.mmsi)) {
                this.style_danger = ' style="color: orange";'
                ship_image_name = feature.properties.model_3d_i + '_yellow.png'
              }

              let customIcon = L.icon({
                iconUrl: require('@/assets/ships/ship_'+ ship_image_name),
                iconSize: [42, 42],
                iconAnchor: [16, 16],
                popupAnchor: [0, -16],
                className: 'custom-icon',
              })

             
              const marker = L.marker(latlng, {
                icon: customIcon,
                rotationAngle: feature.properties.heading,
              })
              this.shipMarkersArray.push(marker) // Agrega el marcador al array (sin capa de Leaflet, para comprobar si el punto está dentro de la zona)
              this.shipMarkers.addLayer(marker) // Agrega el marcador al grupo de marcadores  
              this.shipsDataWithMarker.push(feature)
              this.shipsDataWithMarker[this.shipsDataWithMarker.length-1].marker = marker
              return marker             
            },
            onEachFeature: (feature, layer) => {
              let moving_label = '<p style="color:red; margin-bottom: 10px;margin-top: 0px;">Stopped</p>'
              if (feature.properties.is_moving == "True"){
                  moving_label = '<p style="color:green; margin-bottom: 10px;margin-top: 0px;">Moving</p>'
              }

              const popupContent = `
                <h5  style="margin-bottom: 2px;"> ${feature.properties.ship_name} </h5> ${moving_label} 
                <strong>IMO:</strong> ${feature.properties.IMO}<br>
                <strong>MMSI:</strong> ${feature.properties.mmsi}<br>
                <strong>Ship type:</strong> ${feature.properties.tipo_buq_1}<br>
                <strong>State:</strong> ${feature.properties.estado_nav} <br>
                <div ${this.style_danger} ><strong> Speed:</strong> ${feature.properties.speed} m/s </div>

                <strong>Length of ship:</strong> ${feature.properties.eslora} m <br>
                <strong>Width of ship:</strong> ${feature.properties.manga} m <br>
                <strong>Draft:</strong> ${feature.properties.calado} m <br> <br>

                <strong>Coordinates:</strong> ${feature.geometry.coordinates[0]}
                , ${feature.geometry.coordinates[1]}<br>
              `
              layer.bindPopup(popupContent)


            },
        })
        this.handleShowLayers()
        //this.CheckIfPointInPolygon()
    },
    loadSightingsFromGeoserver: async function () {
        var res = await getGeoserverLayer(process.env.VUE_APP_GEOSERVER_SIGHTINGS_LAYER)
        this.$store.dispatch('ais/changeSightingsData', res.data.features)

        L.geoJson(this.sightingsData, {
            pointToLayer: (feature, latlng) => {
              let groupSize = feature.properties['GROUP SIZE']
              if(!groupSize) groupSize = 1
              var selectSighting = this.typeOfsighting.filter(function(obj) {
                return obj.specie === feature.properties.SPECIES;
              });
              selectSighting = selectSighting[0]
              const marker = L.circle(latlng, {
                  color: selectSighting.color,
                  fillColor: selectSighting.color,
                  fillOpacity: 0.5,
                  radius: groupSize * 100
              })
              //marker.bringToFront();

              this.sightingsMarkers.addLayer(marker) // Agrega el marcador al grupo de marcadores  
              this.SightingsDataWithMarker.push(feature)
              this.SightingsDataWithMarker[this.SightingsDataWithMarker.length-1].marker = marker
              return marker      
              
            },
            onEachFeature: (feature, layer) => {
              const popupContent = `
                <strong>Species:</strong> ${feature.properties.SPECIES}<br>
                <strong>Date:</strong> ${feature.properties.DATE}<br>
                <strong>Group Size:</strong> ${feature.properties['GROUP SIZE']}
              `
              layer.bindPopup(popupContent)
            },
          })

    },
    loadControlZoneFromGeoserver: async function () {
        var res = await getGeoserverLayer('CONTROL_ZONE')
        var estiloCapa = {
            fillColor: 'blue', // Cambia el color de relleno a azul
            color: 'blue',     // Cambia el color del borde a azul
            weight: 2          // Ancho del borde
        };
        this.controlZoneLayer = L.geoJSON(res.data,{ style: estiloCapa });
       
    },
    loadImpactZoneFromGeoserver: async function () {
        var res = await getGeoserverLayer('IMPACT_ZONE')
        var estiloCapa = {
            fillColor: 'green', // Cambia el color de relleno a azul
            color: 'green',     // Cambia el color del borde a azul
            weight: 2          // Ancho del borde
        };
        this.impactZoneLayer = L.geoJSON(res.data,{ style: estiloCapa });

        //this.CreatePolygonToCheckAlerts(res.data.features[0].geometry.coordinates[0][0]) // Se crea este polígono para, posteriormente, comprobar qué barcos están dentro de él
    },
    loadRoutesFromGeoserver: async function () {
      this.routesFeatures = await getGeoserverLayer('ais1')
      this.routesFeatures = this.routesFeatures.data
      this.setGeoJSONLine(this.routesFeatures,'styleLines')
    },
    handleShowLayers(){
      if(this.showLayers.ships){
        this.map.addLayer(this.shipMarkers)
        this.handleFilterByShipType()
      } else
        this.map.removeLayer(this.shipMarkers)

      if(this.showLayers.routes && this.routesLayer)
        this.handleFilterByShipTypeRoutes()
      else if (this.routesLayer)
        this.map.removeLayer(this.routesLayer)

      if(this.showLayers.sightings)
        this.map.addLayer(this.sightingsMarkers)
      else
        this.map.removeLayer(this.sightingsMarkers)

      if(this.showLayers.controlZone && this.controlZoneLayer){
        this.controlZoneLayer.addTo(this.map)
        this.controlZoneLayer.bringToBack()
      }
      else if (this.controlZoneLayer)
        this.map.removeLayer(this.controlZoneLayer)
    
      if(this.showLayers.impactZone && this.impactZoneLayer){
        this.impactZoneLayer.addTo(this.map)
        this.impactZoneLayer.bringToBack()
      }
      else if (this.impactZoneLayer)
        this.map.removeLayer(this.impactZoneLayer)  
    },
    handleFilterByShipTypeRoutes() {
      if(!this.showLayers.routes)
        return
      if(this.routesLayer) this.map.removeLayer(this.routesLayer)

      if (this.selectedShipTypes.length === 0) {
        this.setGeoJSONLine(this.routesFeatures,'styleLines')
      }else{
        this.routesFeaturesFilter = {
          "type": "FeatureCollection",
          "features": this.routesFeatures.features.filter(this.filterByTipoBuqueId(this.selectedShipTypes.map(obj => obj.id)))
        };
        this.setGeoJSONLine(this.routesFeaturesFilter,'styleLines')
      }
      this.map.addLayer(this.routesLayer)
    },
    handleFilterByShipType() {
      if(!this.showLayers.ships)
        return
      if (this.selectedShipTypes.length === 0) {
        this.shipsDataWithMarker.forEach(ship => {
          this.map.removeLayer(ship.marker)
          this.map.addLayer(ship.marker)
        })
      }
      else{
        this.shipsDataWithMarker.forEach(ship => {
          this.map.removeLayer(ship.marker)
          this.selectedShipTypes.forEach(tipoSeleccionado => {
            if(ship.properties.tipo_buque == tipoSeleccionado.id){
              this.map.addLayer(ship.marker)
            }
          })
        })
      }
    },
    handleFilterBySightingType() {
      if (this.selectedSightingTypes.length === 0) {
        this.SightingsDataWithMarker.forEach(sighting => {
          this.map.removeLayer(sighting.marker)
          this.map.addLayer(sighting.marker)
        })
      }
      else{
        this.SightingsDataWithMarker.forEach(sighting => {
          this.map.removeLayer(sighting.marker)
          this.selectedSightingTypes.forEach(tipoSeleccionado => {
            if(sighting.properties.SPECIES == tipoSeleccionado){
              this.map.addLayer(sighting.marker)
            }
          })
        })
      }
    },
    setGeoJSONLine(features,style){
      this.routesLayer = L.geoJSON(features,{ style: this[style],
        onEachFeature: function (feature, layer) {
            const popupContent = `
                <strong>Ship Route:</strong> ${feature.properties.ship_name}<br>
                <strong>Ship type:</strong> ${feature.properties.tipo_buque}<br>
              `
            layer.bindPopup(popupContent)
          }
      });
    },
    styleLines: function(feature) {
      var tipo_buque = feature.properties.tipo_buque;
      var route = feature.properties.route;
      return {
        color: generarColorDesdeString(tipo_buque),
        weight: 3
      };
    },
    filterByTipoBuqueId: function (tipoBuqueIds) {
      return function (feature) {
        return tipoBuqueIds.includes(feature.properties.tipo_buq_1);
      };
    },
    CreatePolygonToCheckAlerts(positionsArray)
    {
      let orderedPositions = []
      positionsArray.forEach(pos => {
        orderedPositions.push([pos[1], pos[0]])
      })
      this.impactPolygon = L.polygon(orderedPositions)
      // this.CheckIfPointInPolygon()
    },
    CheckIfPointInPolygon: function () {

        let inSide = 0
        let outSide = 0
        this.shipMarkersArray.forEach(marker => {
          if (this.impactPolygon.contains(marker.getLatLng())) {
            inSide++
          }
          else {
            outSide++
          }
        })
        this.$store.dispatch('alerts/ChangeShipCount', {inside: inSide, outside: outSide})
      
      
    }
  }
}
</script>
