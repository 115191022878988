<template>
  <div id="app">
    <Navbar/>
    <router-view />
    <FooterSmall/>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";
import FooterSmall from "@/components/Footer/FooterSmall.vue";
export default {
  name: "App",
    components: {
      Navbar,
      FooterSmall
    },
    mounted() {
      // Se inicia el proceso minutal para la obtención de barcos y alertas
      this.$store.dispatch('ais/getShips', process.env.VUE_APP_GEOSERVER_AIS_LAYER)
    }
}
</script>


<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

#app {
  font-family: 'Open Sans', sans-serif, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden !important;
  height: 100%;
}

h1{
  font-family: 'PT sans','Open Sans', sans-serif;
  font-weight: bolder;
}

h2{
    font-family: 'PT sans','Open Sans', sans-serif;
    font-weight: bold;
}

h3{
    font-family: 'PT sans','Open Sans', sans-serif;
    font-weight: bold;
}


</style>

