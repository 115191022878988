import axios from "axios";
import * as localData from "@/services/localData.js";
import Vue from "vue";
import { postData } from "@/services/api.js";

const state = () => ({
  status: "",
  showLogin: false,
  showFiltersWindow: false,
  showAlertsWindow: false,
});

const mutations = {
  status_request(state) {
    state.status = "loading";
  },
  status_ok(state) {
    state.status = "ok";
  },
  status_error(state) {
    state.status = "error";
  },
  showLogin(state, data) {
    state.showLogin = data;
  },
  setFiltersWindow(state, showFilters) {
    state.showFiltersWindow = showFilters
    if(showFilters && state.showAlertsWindow) {
      state.showAlertsWindow = false
    }
  },
  setAlertsWindow(state, showAlerts) {
    state.showAlertsWindow = showAlerts
    if(showAlerts && state.showFiltersWindow) {
      state.showFiltersWindow = false
    }
  }
};

const actions = {
  showLogin({ commit }, data) {
    if (data == true) {
      commit("showLogin", data);
    } else {
      commit("showLogin", data);
    }
  },
  changeFiltersWindow({ commit }, data) {
    commit("setFiltersWindow", data);
  },
  changeAlertsWindow({ commit }, data) {
    commit("setAlertsWindow", data);
  }
};
const getters = {
  status: (state) => state.status,
  showLogin: (state) => state.showLogin,
  showAlertsWindow: (state) => state.showAlertsWindow,
  showFiltersWindow: (state) => state.showFiltersWindow,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
