<template>
<footer class="bg-white border-top">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 ms-5">
        <img src="@/assets/img/eu-commission-logo.jpg" class="me-5"  width="auto" height="30" alt="logo 2">
        <img src="@/assets/img/APC logo.png" class="me-5"  width="auto" height="30" alt="logo 5">
        <img src="@/assets/img/Logo_20_Color.png" class="me-5"  width="auto" height="30" alt="logo 1">
        <img src="@/assets/img/UPV.gif" class="me-5"  width="auto" height="30" alt="logo 4">
        <img src="@/assets/img/UPCT_escudo-institucional-azul.png" class="me-5"  width="auto" height="30" alt="logo 3">
        

      
      </div>
    </div>
  </div>
</footer>

</template>

<script>
export default {
  name: "FooterSmall",
  props: {}
};
</script>

<style scoped lang="scss">

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: $footer-height !important;
  display: flex;
  align-items: center;
}

a{
  color: #343a40;;
}

.bg-light{
    background-color: transparent !important;
    z-index: 2 !important;
}
</style>