import axios from "axios";
import * as localData from "@/services/localData.js";
import Vue from "vue";
import { postData } from "@/services/api.js";

const getDefaultState = () => {
  return {
    UnitSelected: "",
    SpatialscaleSelected: "",
    DateSelected: "",
    TemporalSelected: "year",
  };
};
// initial state for "reset_state purpouses"
const state = getDefaultState();

const mutations = {
  reset_state(state) {
    Object.assign(state, getDefaultState());
  },
  unit_selected(state, data) {
    state.UnitSelected = data;
  },
  spacial_scales_selected(state, data) {
    state.SpatialscaleSelected = data;
  },
  temporal_selected(state, data) {
    state.TemporalSelected = data;
  },
  date_selected(state, data) {
    state.DateSelected = data;
  },
};

const actions = {
  resetState({ commit ,rootGetters}) {
    commit("reset_state")

    rootGetters['map/base_map'].remove(rootGetters['map/criteriaBlocks_layer'])
    rootGetters['map/base_map'].remove(rootGetters['map/GFCMgrid_layer'])
    rootGetters['map/base_map'].remove(rootGetters['map/calculated_d11c1_layer'])

    var layer_name_data = ['reporting_point','reporting_units','reporting_lines','reporting_polygon']
    layer_name_data.forEach(layer_name => {
      rootGetters['map/' + layer_name + '_layer'].definitionExpression = '1=1'
      rootGetters['map/base_map'].remove(rootGetters['map/' +layer_name + '_layer'])
      rootGetters['map/base_map'].add(rootGetters['map/' +layer_name + '_layer'])
    })

  },
  changeSpacial({ commit }, data) {
    commit("spacial_scales_selected", data);
  },
  changeUnit({ commit }, data) {
    commit("unit_selected", data);
  },
  changeDate({ commit }, data) {
    commit("date_selected", data);
  },
  changeTemporal({ commit }, data) {
    commit("temporal_selected", data);
  },
};
const getters = {
  unit_selected: (state) => state.UnitSelected,
  spacial_scales_selected: (state) => state.SpatialscaleSelected,
  date_selected: (state) => state.DateSelected,
  temporal_selected: (state) => state.TemporalSelected,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
