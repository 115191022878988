import axios from "axios";
import * as localData from "@/services/localData.js";
import Vue from "vue";
import { postData } from "@/services/api.js";

const getDefaultState = () => {
  return {
    file_type_noise_events_data: "",
    geometry_type_noise_events_data: "",
    file_type_noise_events_indicator: "",
    spatial_scale_indicator: "",
    decimal_separator: ""
  };
};
// initial state for "reset_state purpouses"
const state = getDefaultState();

const mutations = {
  reset_state(state) {
    Object.assign(state, getDefaultState());
  },
  set_file_type_noise_events_data(state, data) {
    state.file_type_noise_events_data = data;
  },
  set_geometry_type_noise_events_data(state, data) {
    state.geometry_type_noise_events_data = data;
  },
  set_file_type_noise_events_indicator(state, data) {
    state.file_type_noise_events_indicator = data;
  },
  set_spatial_scale_indicator(state, data) {
    state.spatial_scale_indicator = data;
  },
  set_decimal_separator(state, data) {
    state.decimal_separator = data;
  },
};

const actions = {
  resetState({ commit ,rootGetters}) {
    commit("reset_state")
  },
  change_file_type_noise_events_data({ commit }, data) {
    commit("set_file_type_noise_events_data", data);
  },
  change_geometry_type_noise_events_data({ commit }, data) {
    commit("set_geometry_type_noise_events_data", data);
  },
  change_file_type_noise_events_indicator({ commit }, data) {
    commit("set_file_type_noise_events_indicator", data);
  },
  change_spatial_scale_indicator({ commit }, data) {
    commit("set_spatial_scale_indicator", data);
  },
  change_decimal_separator({ commit }, data) {
    commit("set_decimal_separator", data);
  },
};
const getters = {
  file_type_noise_events_data: (state) => state.file_type_noise_events_data,
  geometry_type_noise_events_data: (state) => state.geometry_type_noise_events_data,
  file_type_noise_events_indicator: (state) => state.file_type_noise_events_indicator,
  spatial_scale_indicator: (state) => state.spatial_scale_indicator,
  decimal_separator: (state) => state.decimal_separator,
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
