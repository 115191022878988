<template>
    <div>
    </div>
  </template>
  
  <script>
  // // @ is an alias to /src
  parent.postMessage(location.href, location.origin)

  export default {
    name: "SilentCheckSSO",
    data() {
      return {
        } 
      }
    }  
  </script>
  