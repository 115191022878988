<template>
<div id="alertsblock" class="container-fluid m-0 p-0">
    <div class="alerts_panel">
      <div class="row">
        <div v-if="isNew" class="title-new">New Alert</div>
      </div>

      <div class="alert-settings">
        <div class="p-1">
          <label class="form-label" v-if="isNew">Alert Name </label>
          <input class="form-control" type="text" v-model="name" :v="$v.name" placeholder="Alert name..." />
          <div v-if="$v.name.$error && trySave" >
            <label v-if="!$v.name.required" class="invalid p-0">Required parameter</label>
          </div>
        </div>

        <div class="p-1 row mt-1">
          <div class="col-auto">
            <label class="col-form-label" >Alert type </label>
          </div>
          <div class="col-auto">
            <select  class="form-select" v-model="type" :v="$v.type">
              <option disabled hidden>Select parameter</option>
              <option :value="alert_type.alert_type_id" v-for="alert_type in alert_types" :key="alert_type.alert_type_id">{{alert_type.alert_type_name}}</option>
            </select>
          </div>
          <div v-if="$v.type.$error && trySave ">
            <label v-if="!$v.type.required" class="invalid p-0  w-100">Required parameter</label>
          </div>
        </div>
        <div class="p-1 row mt-0">
          <div class="col-auto">
            <label class="col-form-label">Warning threshold</label>
          </div>
          <div class="col-auto">
            <input class="form-control" v-model="warning_value" :v="$v.warning_value" type="number" placeholder="Warning threshold..." />
          </div>
          <div v-if="$v.warning_value.$error && trySave">
            <label v-if="!$v.warning_value.required" class="invalid p-0  w-100">Required parameter</label>
          </div>
        </div>

        <div class="p-1 row  mt-0">
          <div class="col-auto">
            <label class="col-form-label" >Alert threshold</label>
          </div>
          <div class="col-auto">
            <input class="form-control" v-model="alert_value" :v="$v.alert_value" type="number" placeholder="Alert threshold..." />
          </div>
          <div v-if="$v.alert_value.$error && trySave"  >
            <label v-if="!$v.alert_value.required" class="invalid p-0  w-100">Required parameter</label>
            <label v-if="!$v.alert_value.minValue" class="invalid p-0 w-100">Alert threshold cannot be less than warning threshold</label>
          </div>
        </div>
      </div>
      <div class="p-1 pt-0 row mb-2">
          <label  class="col-form-label" >Users to send the alert</label>
          <select v-model="alert_data.users_list" class="form-select" multiple >
            <option :value=user.username  v-for="user in users_list" :key="user.username" >{{user.username}}  ({{ user.email }})</option>
          </select>
          <span class="m-0 p-0">Note: Hold Ctrl to select multiple users. </span>
      </div>
      <div class="buttons-row">
        <button v-if="isNew && !isValid" class="custom-button button-save" @click="CreateAlert()" >Save</button>
        <button v-if="isNew && isValid" class="custom-button button-save" @click="CreateAlert()" data-bs-dismiss="modal" >Save</button>
        <button v-if="!isNew"  class="custom-button button-save" @click="UpdateAlert(alert_data.id)">Update</button>
        <button v-if="isNew" class="custom-button button-delete"   data-bs-dismiss="modal">Cancel</button>
        <button v-else class="custom-button button-delete" @click="DeleteAlert(alert_data.id)">Delete</button>
      </div>
    </div>
 
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import {
  toaster_success,
  toaster_error,
  toaster_info,
} from "@/services/helper.js";
import {
  required,
  minLength,
  sameAsPassword,
  sameAs,
  not,
  numeric,
  minValue
} from "vuelidate/lib/validators";
export default {
    name: 'Alerts',
    components: {
      
      
    },

    data() {
      return {
        name: '',
        type: '',
        warning_value:0,
        alert_value: 0,
        select_users_list: [],
        id:'',
        trySave: false
      }
    },

    computed:{
      ...mapGetters(
        "alerts",{
          alert_types: 'alertType'
        },
      ),
      ...mapGetters(
        "user",{
          users_list:'users_list'
        }
      ),
      isValid : function () {
        this.$v.name.$touch();
        this.$v.type.$touch();
        this.$v.warning_value.$touch();
        this.$v.alert_value.$touch();
        if (this.$v.name.$error || this.$v.type.$error || this.$v.warning_value.$error || this.$v.alert_value.$error){
          return false
        }
        else{
          return true
        }
      }
    },
    props:{
      alert_data: '',
      isNew: false
    },
    mounted() {
      this.name = this.alert_data.name
      this.type = this.alert_data.type
      this.warning_value = this.alert_data.warning_value
      this.alert_value = this.alert_data.alert_value

    },
    validations() {
      return {
        name: {
          required,
        },
        type: {
          required,
        },
        warning_value: {
          required,
          numeric
        },
        alert_value: {
          required,
          numeric,
          minValue: minValue(this.warning_value)
        }
      }
    },
    watch: {
    },
    methods: {
      CreateAlert: function() {
        this.trySave = true
        if(!this.isValid) return
        this.$store.dispatch('alerts/PostAlertsConfig', {url: 'alerts/rules/', formData: this.FormData()})
      },
      UpdateAlert: function(id) {
        this.trySave = true
        if(!this.isValid) return
        this.$store.dispatch('alerts/PutAlertsConfig', {url: 'alerts/rules/',  id: id, formData: this.FormData()})
        toaster_success("Alert Updated");
      },
      DeleteAlert: function(id) {
        this.$store.dispatch('alerts/DeleteAlertsConfig', {url: 'alerts/rules/', id: id})
      },
      FormData: function() {
        this.alert_data.username = this.$keycloak.userName
        this.alert_data.name = this.name
        this.alert_data.type = this.type
        this.alert_data.warning_value = this.warning_value
        this.alert_data.alert_value = this.alert_value
        return this.alert_data
      }
    },



}




</script>
<style lang="scss">

.alerts {
    padding: 1%;
  }
  
 
  .alerts_panel{
  
  /*display: none;*/
    border: 1px solid #008d98;
    border-radius: 10px;
    background-color: #fefefe;
    padding: 20px;
    box-shadow: 0px 2px 3px 2px rgba(0,0,0,0.15);
    /* margin-top: 15px; */
  }


  .title-new{
    color:#008d98;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .alert-settings {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
  }
  
  label {
    color: #008d98;
    font-size: 0.9rem;
    width: 150px;
  }
 

  /*******  Buttons  ********/
  .buttons-row {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  .custom-button {
    width: 100px;
    border-radius:8px;
    border: none;
    text-decoration: none;
    text-align: center;
    padding: 5px;
    font-size: 0.8rem;
  }
  .button-delete {   
    background-color: rgb(215, 46, 46);
    color: white;
    margin-left: 20px;  
  }
  .button-delete:hover {
    background-color: rgb(255, 120, 120);
  }
  .button-save {   
    background-color: #008d98;
    color: white;    
  }
  .button-save:hover {
    background-color: #03aab6;
  }
</style>