<template>
  <div >
    <iframe ref="unityIframe" class="map-container map-container-unity" :src=webGLurl title="description" frameBorder="0"></iframe> 
    <SubMenu />
    <PanelMapLayers />
    <PanelShipsFilter v-if="showFiltersWindow"/>   
    <PanelAlerts v-if="showAlertsWindow"/>
  </div>
</template>

<script>
import PanelShipsFilter from '@/components/PanelsMap/PanelShipsFilter.vue'
import PanelMapLayers from '@/components/PanelsMap/PanelMapLayers.vue'
import PanelAlerts from '@/components/PanelsMap/PanelAlerts.vue'
import SubMenu from '@/components/Map/SubMenu.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Monitor',
  components: {
    PanelShipsFilter,
    PanelMapLayers,
    PanelAlerts,
    SubMenu
  },
  data() {
    return {
      webGLurl: process.env.VUE_APP_HTTP_S_WEBGL +"://" + process.env.VUE_APP_API_HOST_WEBGL  + process.env.VUE_APP_API_PORT_WEBGL + '/',
      socketUnityMapControl: null,
    }
  },
  mounted() {

    this.$refs.unityIframe.addEventListener("load", this.onIframeLoad);

  },
  computed: { 
    ...mapGetters("ais",{
      selectedShipTypes: 'selectedShipTypes',
      selectedSightingTypes: 'selectedSightingTypes',
      showLayers: 'showLayers',
    }),
    ...mapGetters('ui',{
      showFiltersWindow:'showFiltersWindow',
      showAlertsWindow:'showAlertsWindow',
    })
  },
  watch: {
    selectedShipTypes: {
      handler(newselectedShipTypes, oldselectedShipTypes) {
        if(this.showLayers.ships)
          this.sendControlItemMessage('ToggleValueChangedStatusShip',this.selectedShipTypes.map(obj => obj.id).join(','))
        if(this.showLayers.routes)
          this.sendControlItemMessage('ToggleValueChangedStatusRoutes',this.selectedShipTypes.map(obj => obj.id).join(','))
      },
      deep: true, // Vigila cambios profundos en el objeto (necesario si selectedShipTypes es un objeto)
    },
    selectedSightingTypes: {
      handler(newselectedShipTypes, oldselectedShipTypes) {
        this.sendControlItemMessage('ToggleValueChangedStatusCetacean',this.selectedSightingTypes.join(','))
      },
      deep: true,
    },
    showLayers: {
      handler(newselectedShipTypes, oldselectedShipTypes) {
        this.handleShowLayers()
      },
      deep: true, 
    },
  },
  methods: {
    sendControlItemMessage(type_funcion,item_list){
      this.$refs.unityIframe.contentWindow.postMessage({
          type: 'updateItemsShow',
          funcion: type_funcion,
          itemList: item_list,
        }, '*');
    },

    handleShowLayers(){
      if(this.showLayers.ships && !this.showLayers.routes){
        this.sendControlItemMessage('ToggleValueChangedStatusShip',this.selectedShipTypes.map(obj => obj.id).join(','))
        this.sendControlItemMessage('ToggleValueChangedStatusRoutes','9999')
      }else if(!this.showLayers.ships && this.showLayers.routes){
        this.sendControlItemMessage('ToggleValueChangedStatusShip','9999')
        this.sendControlItemMessage('ToggleValueChangedStatusRoutes',this.selectedShipTypes.map(obj => obj.id).join(','))
      }else if (!this.showLayers.ships && !this.showLayers.routes){
        this.sendControlItemMessage('ToggleValueChangedStatusShip','9999')
        this.sendControlItemMessage('ToggleValueChangedStatusRoutes','9999')
      }
      else{
        this.sendControlItemMessage('ToggleValueChangedStatusShip',this.selectedShipTypes.map(obj => obj.id).join(','))
        this.sendControlItemMessage('ToggleValueChangedStatusRoutes',this.selectedShipTypes.map(obj => obj.id).join(','))
      }
        

      if(this.showLayers.sightings)
        this.sendControlItemMessage('ToggleValueChangedStatusCetacean','')
      else
        this.sendControlItemMessage('ToggleValueChangedStatusCetacean','none')

      if(this.showLayers.controlZone)
        this.sendControlItemMessage('ToggleZoneControl','true')
      else
        this.sendControlItemMessage('ToggleZoneControl','false')

      if(this.showLayers.impactZone)
        this.sendControlItemMessage('ToggleZoneImpact','true')
      else
        this.sendControlItemMessage('ToggleZoneImpact','false')
      
    },
    onIframeLoad() {
      this.$store.dispatch('ais/resetConfig')
    }

  }
}
</script>

<style scoped lang="scss">

.fullscreen-div {
    position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    height: calc(100% - 132px);
    
    // margin: 0;
    // padding: 0;
  }

  /* Estilo para el iframe */
  .fullscreen-iframe {
    width: 100%;
    height: 100%;
    border: none; /* Esto elimina el borde del iframe */
  }
  .map-container-unity{
    top: 58px;
    height: calc(100% - 98px);
    left: -1px;
    right: -1px;
  }

</style>

