<template>
  <div id="panel-ships-filter" class="panel-ships-filter col-4 col-sm-4 col-md-3 col-lg-3 col-xl-2 col-xxl-2">
    <div id="panel-filter" >
      <ul class="list-group list-group-horizontal">
        <button type="button" v-on:click="setPanelShow(0)" class="list-group-item list-group-item-action" :class="{ active: panelShow[0] }">
          <div class="centered-content">
            <img :src="require('@/assets/icons/tab_icon_ship.svg')"  class="icon-panel"  />
          </div>
        </button>
        <button type="button" v-on:click="setPanelShow(1)"  class="list-group-item list-group-item-action"  :class="{ active: panelShow[1] }">
          <div class="centered-content">
            <img :src="require('@/assets/icons/tab_icon_cetacean.svg')"  class="icon-panel" />
          </div>
        </button>
        <button type="button"  class="list-group-item list-group-item-action"  :class="{ active: panelShow[2] }">
          <div class="centered-content">
            <img :src="require('@/assets/icons/tab_icon_dates.svg')"  class="icon-panel" />
          </div>
        </button>
        <button type="button"   class="list-group-item list-group-item-action last-button"  :class="{ active: panelShow[3] }">
          <div class="centered-content">
            <img :src="require('@/assets/icons/tab_icon_compare.svg')"  class="icon-panel" />
          </div>
        </button>
      </ul>
      <div v-if="panelShow[0]" id="shipList" class="panel-container">
        <label class="panel-ships-filter-title">Ship Filter</label>
        <div class="border-main-title"></div>
        <a v-on:click="deselectAllShip()" class="panel-link">Deselect all</a>
        <div class="item-list" v-if = typeOfShip>
          <div  v-for="shipType in typeOfShip" :key="shipType.id">
            <label class="item-label">
              <input type="checkbox" :value="shipType" v-model="selectedShipTypes" :disabled="!showLayers.ships && !showLayers.routes" />
              {{ shipType.ship_type }}
            </label>
          </div>
        </div>
      </div>
      <div v-if="panelShow[1]" id="cetacean" class="panel-container" >
        <label class="panel-ships-filter-title">Sightings  Filter</label>
        <div class="border-main-title"></div>
        <a v-on:click="deselectAllSighting()" class="panel-link">Deselect all</a>
        <div class="item-list" v-if = typeOfsighting>
          <div  v-for="sightingType in typeOfsighting" :key="sightingType.id">
            <label class="item-label">
              <input type="checkbox" :value="sightingType.specie" v-model="selectedSightingTypes" :disabled="!showLayers.sightings" />
              {{ sightingType.specie.charAt(0) +  sightingType.specie.toLowerCase().slice(1)}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

    
<script>
import { mapGetters } from 'vuex'
import { getDataAIS } from '@/services/api'

  export default {
    data() {
      return {
        //selectedShipTypes: []
        panelShow: [1,0,0,0]
      };
    },

    computed: { 
      ...mapGetters("ais",{
        typeOfShip: 'typeOfShip',
        typeOfsighting: 'typeOfsighting',
        showLayers: 'showLayers',
        //selectedShipTypes: 'selectedShipTypes',
      }),
      selectedShipTypes: {
        set (value) { this.$store.dispatch('ais/changeSelectedShipTypes', value) },
        get () { return this.$store.state.ais.selectedShipTypes }
      },
      selectedSightingTypes: {
        set (value) { this.$store.dispatch('ais/changeSelectedSightingTypes', value) },
        get () { return this.$store.state.ais.selectedSightingTypes }
      },
    },
    mounted() {
      if (this.$route.name != 'Home')
        this.$store.dispatch('ais/changeTypesOfData')
    },
    methods: {
      deselectAllShip(){
        this.$store.dispatch('ais/changeSelectedShipTypes', [])
      },
      deselectAllSighting(){
        this.$store.dispatch('ais/changeSelectedSightingTypes', [])
      },
      setPanelShow(panel_id){
       this.panelShow = [0,0,0,0]
       this.panelShow[panel_id] = 1
      }
    },

    watch: {
      
    },

  };
  </script>
  
  <style scoped>
  .panel-ships-filter {
    position: absolute;
    top: 120px; 
    right: 45px; 
    /* width: 20%;  */
    /* height: 75%; */
    /* max-height: 65%; */
    min-width: 300px;
    overflow: hidden;
    background-color: white;
    color: #565656 !important;
    padding: 10px; 
    z-index: 900;
    border-radius: 15px;
    padding: 0;

  }
  .panel-container{
    padding: 15px;
    overflow: auto;
    /* height: 89%; */
    height: 500px;
  }

  .centered-content {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    height: 100%; /* Asegura que el contenedor ocupe toda la altura del botón */
  }

  .icon-panel{
    height: 60%;
    width: 100%;
  }

  .list-group-item {
    border: none;
    border-right: 1px solid #dee2e6;
    border-end-start-radius: 0px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #e8e8e8;
  } 
  .list-group-item.active{
    background-color:  white;
    border-color:  white;
  }
  .last-button {
    border-right: none;
  }

  .panel-ships-filter-title {
    font-size: 1.1em;
    margin-bottom: 5px;
  }
  .panel-link{
    display: block;
    color:#b6b6b6; 
    text-decoration: none;
    width:100%;
    margin-bottom: 5px;
    text-align: right;
    cursor: pointer;
    
  }
  </style>
  