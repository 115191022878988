<template>
  <div class="container-alert">
    <div class="container-fluid panel-alert-layer ps-5 pe-5">
        <div class="navbar-brand"><h2 id="alert">Alert Settings</h2></div>
          <div class="border-alarm-title"></div> 
          <div align="right" class="add_alert mt-2 mb-2">
            <button class="btn btn-outline-primary mb-2" @click="addNewAlert = !addNewAlert" data-bs-toggle="modal" data-bs-target="#newAlertRule">NEW +</button>
          </div>
          <div class="row ">
            <Alerts  class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 p-2 " v-for="alert in configList" :key="alert.alert_id" :alert_data=alert></Alerts>
          </div>
          
          <div class="modal fade" id="newAlertRule" tabindex="-1" aria-labelledby="newAlertRuleLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content-alert modal-content" >
                <div class="modal-body m-0 p-0">
                  <Alerts  :alert_data=emptyAlert :isNew="true"></Alerts>
                </div>
              </div>
            </div>
          </div>
    </div>
  </div>
</template>



<script>
import { getData, postData } from  '@/services/api'
import Alerts from '@/components/Alerts/Alerts.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'AlertsSettings',
  components: {
    'Alerts' : Alerts
    
    
  },

  data() {
    return {
      addNewAlert: false,
      emptyAlert: {
        id: 0,
        name: '',
        type: 0,
        user_id: '',
        warning_value: '',
        alert_value: '',
        users_list: []
      }
    }
  },

  computed: {
    ...mapGetters(
      "alerts",{
        alerts: 'alerts',
        configList: 'configList',
      }
    ),
  },
  watch: {
    addNewAlert: function () {
      this.emptyAlert = {
        id: 0,
        name: '',
        type: 0,
        user_id: '',
        warning_value: '',
        alert_value: '',
        users_list: []
      }
    }
  },

  mounted() {
    this.$store.dispatch('alerts/GetAlertsConfig','')
    this.$store.dispatch('user/GetUsersList','')
  },


  methods: {
  }

  

}

</script>

<style lang="scss">

.container-alert{
  margin-bottom: 70px;
}

.modal-content-alert{
  border-radius: 20px !important;
}

// .map-container{
//   top:78px;
//   height: calc(100% - 130px);
// }

#alert{
  font-size: 1.5em;
  
}

.border-alarm-title{
  border-top: 2px solid #dee2e6;
  width: 100%;
  padding-bottom: 5px;
  
}

.panel-alert-layer{
  color: $panel-layer-color !important;
}

.add_alert{
  width:100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// .new-alert{
//   width: 100%;
//   height: 100%; 
//   background-color: rgba(255,255,255,0.8);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: absolute;
//   top: 0;
// }
</style>
