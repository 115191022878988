import axios from "axios";

const getlocal = (key) => {
  return localStorage.getItem(key);
};

const getArraylocal = (key) => {
  if (localStorage.getItem(key))
    return localStorage.getItem(key).split(',');
};

const setlocalToken = (key, value) => {
  localStorage.setItem(key, value);

};

const setlocal = (key, value) => {
  localStorage.setItem(key, value);
};

const deletelocalToken = (key) => {
  localStorage.removeItem(key);
};

const deleteAll = () => {
  localStorage.clear();
};

export { getlocal, getArraylocal, setlocalToken, deletelocalToken, setlocal, deleteAll };
